<template>
   <div>
      <v-row>
         <v-col cols="12">
            <v-row>
               <v-col cols="12">
                  <v-btn color="primary" class="m-auto" x-large :to="{ name: 'voucherTypeCreate' }" :disabled="isFetching">Add</v-btn>
               </v-col>
            </v-row>
         </v-col>
         <v-col cols="12">
            <TableView :data="data" :headers="headers" detailPage="voucherTypeDetail" />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import dummyData from "@/components/dummy-data"
import { mapActions, mapState } from "vuex";
import TableView from "@/components/TableView"

export default {
   name: 'VoucherType',
   components: {
      TableView,
   },
   data() {
      return {
         responseData: [],
         headers: [
            { text: "Service ID", value: "service_no" },
            { text: "Service title", value: "title" },
         ],
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),

      data() {
         return this.responseData;
      },
   },
   methods: {
      ...mapActions(["sendRequest"], "request"),
      async initData() {
         const resp = await this.sendRequest({ url: '', method: 'GET', params: {} });
         if(resp.data) {
            this.responseData = dummyData.voucher_setting_list.map(item => {
               return {
                  ...item,
               }
            });
         }
      },
   },
   async mounted() {
      await this.initData();
   }
}
</script>
